<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 col-lg-3">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Mill" @change="(event) => millOnChange(event)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-6 col-lg-3">
                                <v-select v-model="machTypeModel" :items="machTypeLists" item-value="mach_type" :item-text="item => item.mach_type +' - '+ item.descr" label="Machine Type" @change="(event) => machTypeOnChange(event)" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-6 col-lg-1">
                                <v-select v-model="machIDModel" :items="machIDLists" item-value="mach_id" item-text="mach_id" label="Mach. ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-6 col-lg-2">
                                <v-select v-model="shiftModel" :items="shiftLists" item-value="value" item-text="text" label="Working Shift" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-6 col-lg-3">
                                <v-autocomplete
                                    v-model="orderIDModel"
                                    :items="orderIDLists"
                                    item-value="CustomerOrderNo"
                                    item-text="NamaCustomer"
                                    :search-input.sync="searchOrder"
                                    cache-items
                                    flat
                                    hide-no-data
                                    hide-details
                                    clearable
                                    label="Order ID" prepend-icon="mdi-cart"
                                    dense
                                    ></v-autocomplete>
                            </div>
                            <div class="col-6 col-lg-4">
                                <v-autocomplete
                                    v-model="coilIDModel"
                                    :items="coilIDLists"
                                    item-value="coil_id"
                                    item-text="descr"
                                    :search-input.sync="searchCoil"
                                    cache-items
                                    flat
                                    hide-no-data
                                    hide-details
                                    clearable
                                    label="Coil ID" prepend-icon="mdi-cart"
                                    dense
                                    ></v-autocomplete>
                            </div>
                            
                            <div class="col-6 col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" prepend-icon="mdi-calendar-check" dense></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-6 col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" prepend-icon="mdi-calendar-check" dense></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-6 col-lg-2">
                                <v-select v-model="statModel" :items="statLists" item-value="stat" item-text="text" label="Stat" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-12 col-lg-2 text-right">
                                <v-btn class="mb-2" color="primary" elevation="2" small rounded @click="getData()" dense>Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12" v-show="cardResult">

                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">

                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :loading="loadingDatatable" dense>
                                
                                <template v-slot:[`item.showDetail`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, }">
                                            <v-chip 
                                                v-if="item.stat != 'D'"
                                                @click="showItemDetail(item)" color="blue" link small v-on="on" dark>Details
                                            </v-chip>
                                        </template>
                                        <span>Show Details</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, }">
                                            <v-chip
                                                v-if="item.stat == 'D'"
                                                color="red"
                                                @click="showItemDetail(item)" v-on="on"
                                                link small dark
                                                >
                                            Details
                                            </v-chip>
                                        </template>
                                    <span>Show Details</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>

                        </div>
                    </div>
                </div>
            
            </div>

            <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true" v-show="cardResultDetail">
                <div class="modal-dialog" style="max-width: 80%;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="detailModalLabel">Detail Coil ID : {{ coil_id_title }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">

                                <v-data-table :headers="headersDetail" :items="itemListsDetail" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense></v-data-table>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Prod. performa',
                disabled: false,
                href: '#',
                },
                {
                text: 'Prod. Data VS Logger',
                disabled: true,
                href: '#',
                },
            ],
            cardResult: false,
            millModel: '',
            millLists: [],
            orderIDModel: '',
            orderIDLists: [],
            machTypeModel: '',
            machTypeLists: [],
            machIDModel: '',
            machIDLists: [],
            shiftModel: '',
            shiftLists: [
                {
                    'text':'ALL',
                    'value':''
                },
                {
                    'text':'1',
                    'value':'1'
                },
                {
                    'text':'2',
                    'value':'2'
                },
                {
                    'text':'3',
                    'value':'3'
                }
            ],
            coilIDModel: '',
            coilIDLists: [],
            dateStartModal: false,
            dateStartModel: '',
            dateStartFormatted: '',
            dateEndModal: false,
            dateEndModel: '',
            dateEndFormatted: '',
            statModel: '',
            statLists: [
                {
                    'text':'ALL',
                    'stat':''
                },
                {
                    'text':'COIL SISA',
                    'stat':'A'
                },
                {
                    'text':'COIL HABIS',
                    'stat':'D'
                },
                {
                    'text':'DIKIRIM',
                    'stat':'S'
                },
            ],
            headers: [
                { text: 'COIL ID', value: 'coil_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESCR.', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'length', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD.', value: 'prod', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LOGGER', value: 'logger', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetail', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            itemLists: [],
            loadingDatatable: false,
            headersDetail: [
                { text: 'COIL ID', value: 'coil_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE', value: 'tanggal', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SHIFT', value: 'shift', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MILL ID', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. TYPE', value: 'mach_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. ID', value: 'mach_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESCR.', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'length', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER ID', value: 'order_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD.', value: 'prod', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LOGGER', value: 'logger', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'STAT', value: 'stat', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            itemListsDetail: [],
            loadingDatatableDetail: false,
            cardResultDetail: false,
            coil_id_title: '',
            searchOrder: null,
            searchCoil: null
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/LoggerProd`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.millLists = res.data.mill
                this.machTypeLists = res.data.mach_type
                this.machIDLists = res.data.mach_id
                
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data,
                        icon: 'error',
                    })
                }

            })

        },

        async getData(){

            this.cardResult = true
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)
            this.itemLists = []

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/LoggerProd/getData`, { 

                mill: this.millModel ? this.millModel : '',
                mach_type: this.machTypeModel ? this.machTypeModel : '',
                mach_id: this.machIDModel ? this.machIDModel : '',
                shift: this.shiftModel ? this.shiftModel : '',
                order_id: this.orderIDModel ? this.orderIDModel : '',
                coil_id: this.coilIDModel ? this.coilIDModel : '',
                dateFrom: this.dateStartFormatted,
                dateTo: this.dateEndFormatted,
                stat: this.statModel ? this.statModel : ''

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                if(res.data.result.length != 0){
                    this.cardResult = true
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.cardResult = false
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        icon: 'warning',
                        text: 'Data not found !'
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

            

        },

        async showItemDetail(id){

            $('#detailModal').modal('show')
            this.loadingDatatableDetail = true
            this.itemListsDetail = []

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/LoggerProd/getDataDetail`, { 

                    coil_id: id.coil_id ? id.coil_id : ''

                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                })
                .then(res => {

                    this.coil_id_title = id.coil_id
                    this.loadingDatatableDetail = false
                    this.itemListsDetail = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

        },

        async millOnChange(id){

            this.machTypeLists = []

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/LoggerProd/getMachineTypeByMill?mill=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.machTypeLists = res.data.mach_type
                
            })
            .catch(err => {
                
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data,
                        icon: 'error',
                    })
                }

            })

        },

        async machTypeOnChange(id){

            this.machIDLists = []

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/LoggerProd/getMachineId?mill_id=${this.millModel}&mach_type=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.machIDLists = res.data.mach_id
                
            })
            .catch(err => {
                
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data,
                        icon: 'error',
                    })
                }

            })

        },

        async getOrderID(id)
        {
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/LoggerProd/getOrderID?orderid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {

                        this.orderIDLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

        },

        async getCoilUnit(id)
        {
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/LoggerProd/getCoilUnit?coil_id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {

                        this.coilIDLists = res.data.result
                        
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    },
    watch: {
        searchOrder (val) {
            val && val !== this.orderIdModel && this.getOrderID(val)
        },
        searchCoil (val) {
            val && val !== this.coilIDModel && this.getCoilUnit(val)
        },
    }
    
}
</script>

<style scoped>

.chat-online {
    color: #34ce57
}

.chat-offline {
    color: #e4606d
}

.chat-messages {
    display: flex;
    flex-direction: column;
    max-height: 800px;
    overflow-y: scroll
}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0
}

.chat-message-left {
    margin-right: auto
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto
}
.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}
.px-4 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
}
.flex-grow-0 {
    flex-grow: 0!important;
}
.border-top {
    border-top: 1px solid #dee2e6!important;
}


</style>